import {
  agent as agentApi,
  terminal as terminalApi,
  pay24 as pay24Api,
} from '../api'
import { MESSAGE_TYPE } from '../constants'
import { i18n } from '../translate'
import {
  dateFormat,
  dateFormatDB,
  fileToBase64,
  isMobile,
  isWeb,
  platform,
  sleep,
  PERIOD,
} from '../utils'
import { downloadExcel } from '../utils/file'
import {
  AgentAktTypes,
  AgentAllServicesModel,
  AgentCategoryModel,
  AgentFavoriteModel,
  AgentFavoritesModel,
  AgentGendersModel,
  AgentHistoryModel,
  AgentHistoryOperationTypeModel,
  AgentLastPaymentsModel,
  AgentListTypeModel,
  AgentLoadPaymentsModel,
  AgentLoadResultModel,
  AgentProfileModel,
  AgentReportStatusModel,
  AgentServicesCommissionsModel,
  AgentTemplates,
  SubAgentHistoryModel,
  SubAgentModel,
} from './models/Agent'
import { editableModel } from './editable'
import rootStore from './RootStore'
import ServicesStore from './ServiceStore'
import round from 'lodash/round'
import { toJS } from 'mobx'
import { applySnapshot, getSnapshot, Instance, types } from 'mobx-state-tree'
import moment from 'moment/moment'

const allSubAgentData = {
  FIO: 'Все',
  activation_code: null,
  address: '',
  avatar: null,
  balance: 0,
  birthday: null,
  blocking_reason: null,
  city: null,
  client_id: 1,
  country: null,
  data: {},
  email: null,
  first_name: '',
  id: null,
  ip_address: '',
  is_activated: true,
  is_blocked: false,
  is_closed: false,
  is_identified: false,
  last_name: '',
  limit: 0,
  login: null,
  nickname: null,
  notifications: null,
  passport: null,
  passport_expire: null,
  passport_issue: null,
  passport_issuer: null,
  patronymic: null,
  phonenumber: '',
  register_date: null,
  schedule_info: null,
  settings: null,
  sex: null,
  sub_code: null,
  template_id: null,
  trial: null,
  used: null,
  user_type: null,
}

export const FORM = {
  INITIAL: 'initial',
  CHECK: 'check',
  PAYMENT_3D: 'payment3D',
  PAID: 'paid',
  FAIL: 'FAIL',
}

const SUB_AGENT_HISTORY_TYPES = [
  { id: 1, name: 'payment', view: i18n.t('subagent_payments') },
  { id: 2, name: 'transfer', view: i18n.t('subagent_transfer') },
  { id: 3, name: 'refill', view: i18n.t('subagent_deposits') },
  { id: 4, name: 'balance', view: i18n.t('balance') },
]

const properties = {
  id: 0,
  balance: types.optional(types.number, 0),
  terminal_balance: 0,
  status: types.frozen(),
  translationsCategory: types.maybe(types.number),
  favorites: types.array(AgentFavoritesModel),
  favorite: types.optional(AgentFavoriteModel, {
    name: '',
    requisite: '',
    options: {},
    service_id: 0,
  }),
  page: types.optional(types.number, 1),
  page_size: types.optional(types.number, 25),
  payData: types.frozen(),
  paymentState: types.optional(types.string, FORM.INITIAL),
  step: 0,
  sub_code: types.maybeNull(types.string),
  templates: types.optional(types.array(AgentTemplates), []),
  subAgent: types.optional(SubAgentModel, () => SubAgentModel.create()),
  subAgents: types.optional(types.array(SubAgentModel), []),
  user_type: 0,
  genders: types.optional(types.array(AgentGendersModel), [
    { id: 1, name: i18n.t('male') },
    { id: 0, name: i18n.t('female') },
  ]),
  first_name: types.maybeNull(types.string),
  last_name: types.maybeNull(types.string),
  template: types.optional(AgentTemplates, () => AgentTemplates.create()),
  templateName: types.maybeNull(types.string),
  reportFilename: types.maybeNull(types.string),
  reportStatus: types.optional(AgentReportStatusModel, {
    job_id: null,
    status: null,
    message: null,
    progress: null,
  }),
  agentProfile: types.optional(AgentProfileModel, () =>
    AgentProfileModel.create(),
  ),
  agentProfileTab: 'data',
  agentLoadResults: types.array(AgentLoadResultModel),
  agentLoadLastPayments: types.array(AgentLastPaymentsModel),
  agentLoadPayments: types.optional(types.array(AgentLoadPaymentsModel), [
    { requisite: '', amount: '' },
    { requisite: '', amount: '' },
    { requisite: '', amount: '' },
    { requisite: '', amount: '' },
  ]),
  servicesCommission: types.array(AgentServicesCommissionsModel),
  favoriteId: types.maybeNull(types.number),
  history: types.optional(types.array(AgentHistoryModel), []),
  total: types.frozen(),
  operationType: types.maybeNull(AgentHistoryOperationTypeModel),
  date_from: dateFormatDB(moment()),
  date_to: dateFormatDB(moment()),
  period: types.optional(types.string, PERIOD.DAY),
  searchText: '',
  query: '',
  sum: types.maybeNull(types.string),
  comment: types.maybeNull(types.string),
  currentPage: 0,
  orderBy: types.maybeNull(types.string),
  agentListType: types.optional(AgentListTypeModel, {
    id: 'all',
    name: i18n.t('all'),
    stat: 'total',
  }),
  reconStatements: types.frozen(),
  total_comm: 0,
  total_bonus: 0,
  total_sum: 0,
  agentAktTypes: types.optional(types.array(AgentAktTypes), [
    { id: 'bank', name: 'Банк' },
    { id: 'agent', name: 'Агент' },
  ]),
  agentAktType: 'bank',
  columns: types.frozen([]),
  horizontal: false,
  statistics: types.frozen([]),
  agent: types.maybeNull(types.number),
  agentStatisticsStatus: types.maybeNull(types.string),
  subAgentHistoryPage: 1,
  subAgentHistory: types.optional(types.array(SubAgentHistoryModel), []),
  total_good: 0,
  count_good: 0,
  total_bad: 0,
  count_bad: 0,
  total_count: 0,
  blockButton: false,
  packagePay: '',
  packageService: types.optional(types.frozen(), null),
}

let request
let subRequest
let agentRequest

const actions = (_self) => {
  const self = _self as IAgentStore
  return {
    afterCreate() {
      self.setAgent()
    },
    async getUserInfo(silent = false) {
      try {
        const r = await agentApi.getUserInfo({}, silent)
        if (r && r.user_info && r.user_info.balance) {
          self.setBalance(r.user_info.balance[0]?.amount)
        }
        if (r && r.user_info && r.user_info.sub_code) {
          self.setSubCode(r.user_info.sub_code)
        }
        if (r && r.user_info) {
          self.setUserType(r.user_info?.user_type || 0)
        }
        if (r && r.user_info && r.user_info.id) {
          self.setUserId(r.user_info.id)
        }
        if (
          r &&
          r.user_info &&
          r.user_info.agent_info?.first_name &&
          r.user_info.agent_info?.last_name
        ) {
          self.setName('first_name', r.user_info.agent_info.first_name)
          self.setName('last_name', r.user_info.agent_info.last_name)
        }
        if (r && r.user_info && r.user_info.data) {
          self.setPackagePay(r.user_info.package_pay)
        }
        return undefined
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async getAgentProfile() {
      self.setAgentLoading(true)
      const r = await agentApi.getUserInfo({ agent_profile: true }, false)
      self.setAgentProfile(r.user_info)
      self.setAgentLoading(false)
    },

    async getTerminalBalance() {
      try {
        const r = await terminalApi.reportBalances()
        if (r.data) self.terminal_balance = r.data[0].balance
        return undefined
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async searchServices(search) {
      const response = await agentApi.getServicesSearch(search)
      const filteredServices = response.services.filter(
        (s) => !s.is_down && !s.blocked,
      )
      self.setServices(filteredServices)
      return self.services
    },

    async saveFavorite(favorite) {
      if (!favorite) return
      let params: any = {
        command: !favorite.id ? 'add' : 'edit',
        service_id: favorite.service_id?.toString(),
        contact_name: favorite.name,
        options: favorite?.options,
        requisite: favorite.requisite,
      }

      if (favorite.amount) params.amount = favorite.amount
      if (favorite.id) params.contact_record_id = favorite.id.toString()

      const response = await agentApi.favorites(params)
      self.fetchFavorites()
      return response
    },
    async deleteFavorite(id) {
      if (!id) return
      const params = { command: 'delete', contact_record_id: id.toString() }
      const response = await agentApi.favorites(params)
      self.fetchFavorites()
      return response
    },
    async fetchFavorites() {
      const data = await agentApi.favorites(
        { command: 'list' },
        self.favorites.length > 0,
      )
      self.setFavorites(data.contacts || [])
      return data
    },
    async requisiteCheck(source) {
      const { payData } = self

      const params = { ...payData }
      if (!params.options) {
        params.options = {}
      }
      // params.options.explanation = payData.comment

      try {
        const r = await pay24Api.paymentCheck({ source, params })
        payData.customer_name = r.customer_name
          ? r.customer_name.trim()
          : 'найден'
        if (r.extra && r.extra.fixed_amount) {
          payData.fixed_amount = parseFloat(r.extra.fixed_amount)
        }
        payData.paymentCommission = r.commission
        payData.acquiringCommission = source.commission
        payData.totalAmount = self.calc_total(
          payData.amount,
          r.commission,
          source.commission,
        )
        payData.commission = round(payData.totalAmount - payData.amount, 2)
        return r
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async pay(source, callback) {
      try {
        const { payData } = self
        payData.payload = await pay24Api.paymentStart({
          source: source,
          ...self.payData,
        })
        if (callback) {
          callback()
        }
        self.setPaymentState(FORM.PAYMENT_3D)
        try {
          let resp = await pay24Api.paymentPoll(
            self.payData.payload.operation_id,
          )
          if (resp.status === 'fail') {
            rootStore.setMessage({
              type: MESSAGE_TYPE.WARNING,
              text: resp.message,
            })
            payData.errorMessage = resp.message
            self.setPaymentState(FORM.FAIL)
          } else {
            self.setPaymentState(FORM.PAID)
          }
        } catch (e) {
          if (e instanceof Error) {
            rootStore.setMessage({
              type: MESSAGE_TYPE.WARNING,
              text: e.message,
            })
          }
          self.setPaymentState(FORM.FAIL)
        }
        return undefined
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async fetchTemplates() {
      const response = await agentApi.getTemplates(
        {},
        self.templates.length > 0,
      )
      self.setTemplates(response.templates)
    },
    async setAgentTemplate(data) {
      return agentApi.agentTemplate(data, true)
    },
    async saveSubAgent() {
      const param: any = { ...self.subAgent }
      if (param.sexObj) {
        param.sex = param.sexObj.id
        delete param.sexObj
      }
      let addAgentData
      if (!self.subAgent.id) {
        addAgentData = { ...self.subAgent }
        delete addAgentData.id
      }

      return agentApi.saveAgent({
        entity: !self.subAgent.id ? addAgentData : param,
      })
    },
    async saveAgent() {
      return agentApi.setUserInfo(self.agentProfile)
    },
    async saveTemplate(templateName) {
      return agentApi.saveTemplate({ template: templateName })
    },
    async setTemplate(template) {
      self.fetchAllServices(null, null, true)

      self.setValue('template', toJS(template))
      if (self.hasTemplateDaySettings) {
        self.template.setValue('showDaySettings', true)
      }
      if (template.commission) {
        const commission = JSON.parse(template.commission)
        self.template.setValue('commission', commission)
      } else {
        self.template.setValue('commission', {})
      }
    },
    async saveCommission() {
      if (!self.template || !self.template.commission) return
      let templateServices: any = []
      const commission: any =
        typeof self.template.commission !== 'string' &&
        getSnapshot(self.template.commission)
      Object.keys(commission).map((key) => {
        if (
          self.template.commission &&
          typeof self.template.commission !== 'string'
        ) {
          templateServices.push({
            service_id: parseInt(key),
            commission: self.template.commission.get(key)?.commission,
            max_amount: self.template.commission.get(key)?.max_amount,
            is_disabled: self.template.commission.get(key)?.is_disabled,
          })
        }
      })

      const data: any = {
        template_id: self.template.id,
        template: self.template.template,
        services: templateServices,
      }

      if (self.template.data.allow_duplication) {
        data.allow_duplication = self.template.data.allow_duplication
      }

      if (self.hasTemplateDaySettings) {
        const s: any = {}
        s.start_time = self.template.data.start_time
        s.end_time = self.template.data.end_time
        s.Mon = self.template.data.Mon
        s.Tue = self.template.data.Tue
        s.Wed = self.template.data.Wed
        s.Thu = self.template.data.Thu
        s.Fri = self.template.data.Fri
        s.Sat = self.template.data.Sat
        s.Sun = self.template.data.Sun
        data.schedule = s
      }
      return agentApi.saveCommissions(data)
    },
    async report(service_name, parameters) {
      parameters.unlimited = true
      const params = { service_name, parameters }
      const { job_id } = await agentApi.getReport(params)
      self.setReportStatus('job_id', job_id)
      self.setReportStatus('status', 0)
      self.setReportStatus('message', null)

      while (self.reportStatus.job_id) {
        await sleep(1000)
        const reportStatus_job_id = self.reportStatus.job_id
        const { status, message, name, progress } = await agentApi.jobCheck(
          reportStatus_job_id,
        )

        self.setReportStatus('status', status)
        self.setReportStatus('progress', progress)
        if (status !== 0) {
          self.setReportStatus('job_id', null)
          self.setReportStatus('status', null)
          self.setReportStatus('message', message)
          self.setReportStatus('progress', null)

          if (message) {
            rootStore.setMessage('Произошла ошибка при скачивании excel')
          }

          if (status === 1) {
            self.setReportFileName(name)
          }
        }
      }
    },
    async getLastPayments(params) {
      self.setAgentLoading(true)
      agentApi
        .getLastPayments(params, true)
        .then((resp) => {
          self.setAgentLoadLastPayments(resp.History)
        })
        .finally(() => self.setAgentLoading(false))
    },
    async agentLoadPay() {
      self.setBlockButton(true)
      let params = {
        service_id: [1, 2, 3, 1494, 1522],
        limit: 10,
        client: 1,
      }
      let payments = self.agentLoadPayments.filter(
        (p) =>
          (p.requisite || '').trim().length > 0 &&
          parseFloat(String(p.amount || 0)) > 0,
      )
      if (payments.length === 0) {
        self.resetPayments()
        self.setBlockButton(false)
        return
      }

      payments.forEach((p) => {
        p.amount = p.amount.toString()
      })
      try {
        const r = await agentApi.quickPayment({ payments })
        self.setAgentLoadResults(r.results)
        const messages = r.results?.map((res) => res.message)
        rootStore.setMessage({
          type: MESSAGE_TYPE.INFO,
          text: messages.join('\n'),
        })
        self.resetPayments()
        self.getLastPayments(params)
        setTimeout(() => {
          self.deleteResult(null)
        }, 5000)
      } catch (e) {
        console.error('QuickPayment error', e)
      } finally {
        self.setBlockButton(false)
      }
    },

    async onSubmit() {
      if (self.service?.id) {
        self.setFavorite('service_id', self.service?.id)
      }
      let params: any = { ...self.favorite }
      if (self.favoriteId) params.id = self.favoriteId
      return self.saveFavorite(params)
    },
    transfer(agent, amount?, comment?) {
      return agentApi.internalTransfer({
        customer_id: agent.sub_code,
        amount: amount || agent.sum,
        comment: comment || agent.comment,
      })
    },
    agentGetSubAgents() {
      agentApi.getSubAgents().then((r) => {
        const histories = r.agents
        histories.unshift(allSubAgentData)
        const data = histories.map((a) => {
          return { ...a, FIO: a.FIO ? a.FIO : a.login || a.id }
        })
        self.setSubAgents(data)
      })
    },
    agentStatisticsGetData() {
      self.setAgentLoading(true)
      agentApi.getStats(self.getAgentStatisticsParams()).then((r) => {
        self.setStatistics(r.Stats)
        const columns = r.Columns.map((a) => {
          return { name: a.caption, selector: (row) => row[a.binding] }
        })
        self.setValue('columns', columns)
        self.setAgentLoading(false)
      })
    },
    getSubHistories(reset?: boolean, type?) {
      self.setAgentLoading(true)
      if (type || self.operationType === null) {
        const historyType = SUB_AGENT_HISTORY_TYPES.find(
          (obj) => obj.name === type,
        )
        self.setValue('operationType', historyType || null)
      }
      self.setValue(
        'subAgentHistoryPage',
        reset ? 1 : self.subAgentHistoryPage + 1,
      )

      const param: any = {
        date_from: dateFormat(self.date_from),
        date_to: dateFormat(self.date_to),
        operation_type: self.operationType?.id,
        requisite: self.query,
      }
      if (self.agent) {
        param.user_id = self.agent
      }
      if (self.service) {
        param.service_id = self.service.id
      }
      if (self.category?.id) {
        param.category_id = self.category?.id
      }
      if (self.status?.id) {
        param.status_id = self.status?.id
      }
      subRequest = agentApi.getSubHistory(param)
      subRequest.then((r) => {
        self.setValue('subAgentHistory', r.History)
        self.setValue('count_good', r.count_good)
        self.setValue('total_good', r.total_good)
        self.setValue('count_bad', r.count_bad)
        self.setValue('total_bad', r.total_bad)
        self.setAgentLoading(false)
      })
    },
    async getFavorite(params) {
      self.setAgentLoading(true)
      self.resetFavorite()
      if (params?.id) {
        const res = await agentApi.getFavorite({ id: params?.id })
        self.setFavorite('name', res.data.name)
        self.setFavorite('service_id', res.data.service_id)
        self.setFavorite('requisite', res.data.requisite)
        self.setFavorite('options', res.data.data.options)
        self.setFavoriteId(res.data.id)
        await self.fetchAllServices(null, res.data.service_id, true)
      } else if (params?.requisite && params?.service_id) {
        self.setFavorite('requisite', params?.requisite)
        self.setFavorite('service_id', params?.service_id)
        self.setFavorite('options', params?.options)
        await self.fetchAllServices(null, params?.service_id, true)
      } else {
        self.setFavoriteId(null)
      }
      self.setAgentLoading(false)
    },
    getHistory() {
      if (self.operationType?.id === '4') {
        const params: any = {
          operation_type: self.operationType?.id,
          date_from: dateFormat(self.date_from),
          date_to: dateFormat(self.date_to),
          page: self.page,
          page_size: self.page_size,
          requisite: self.query,
        }
        if (self.service?.id) {
          params.service_id = self.service?.id
        }
        request = agentApi.getHistoryBalance(params)
        request.then((r) => {
          self.setHistory(r.operations || [])
          self.setTotal({
            incoming: r.incoming,
            outgoing: r.outgoing,
            debit: r.debit,
            credit: r.credit,
          })
          self.setTotalCount(r.total_count)
          self.setAgentLoading(false)
        })
      } else {
        const params: any = {
          operation_type: self.operationType?.id,
          start_date: dateFormat(self.date_from),
          end_date: dateFormat(self.date_to),
          mobile: !isWeb,
          page: self.page,
          page_size: self.page_size,
          requisite: self.query,
        }
        if (self.status?.id && self.operationType?.id === '1') {
          params.status_id = self.status.id
        }

        if (self.category?.id && self.operationType?.id === '1') {
          params.category_id = self.category?.id
        }

        if (self.translationsCategory && self.operationType?.id === '2') {
          params.transfer_type = self.translationsCategory
        }

        if (self.service?.id && self.operationType?.id === '1') {
          params.service_id = self.service?.id.toString()
        }

        request = agentApi.getHistory(params)
        request.then((r) => {
          self.setHistory(r.data || [])
          self.setTotal({
            successCount: r.count_good,
            successSum: r.total_good,
            badCount: r.count_bad,
            badSum: r.total_bad,
          })
          self.setTotalCount(r.total_count)
          self.setAgentLoading(false)
        })
      }
    },
    requestCancel() {
      request && request.cancel()
    },
    subRequestCancel() {
      subRequest && subRequest.cancel()
    },
    agentRequestCancel() {
      agentRequest && agentRequest.cancel()
    },
    searchSubAgents(more?, query?) {
      self.setAgentLoading(true)
      let params: any = {
        type: self.agentListType.id,
        light: true,
        page: more ? self.currentPage + 1 : 1,
        search: query,
      }
      if (self.orderBy) {
        params.orderBy = self.orderBy
      }
      agentRequest = agentApi.getSubAgents(params)
      agentRequest
        .then((r) => {
          if (more) {
            self.setSubAgents([...self.subAgents, ...r.agents])
            self.setCurrentPage(self.currentPage + 1)
          } else {
            self.setSubAgents(r.agents)
          }
        })
        .finally(() => self.setAgentLoading(false))
    },
    getSubAgent(id) {
      const req = agentApi.getSubAgentDetails(id)
      req.then((r) => {
        self.setSubAgent(r.subagent)
      })
      return req.cancel
    },
    setPaymentState(state: string = FORM.INITIAL) {
      self.paymentState = state
    },
    setAgent() {
      self.isAgent = true
    },
    setStep(step = 1) {
      self.step = step
    },
    setStatus(status) {
      self.status = status
    },
    setTotalCount(count = 0) {
      self.total_count = count
    },
    setTranslationsCategory(category) {
      self.translationsCategory = category
    },
    setName(whichName: string, val: string) {
      self[whichName] = val
    },
    setBalance(balance: number) {
      self.balance = balance
    },
    setSubCode(code: string) {
      self.sub_code = code
    },
    setUserType(type: number) {
      self.user_type = type
    },
    setUserId(id: number) {
      self.id = id
    },
    setPackagePay(val: string) {
      self.packagePay = val
    },
    setFavorites(favorites) {
      self.favorites = favorites
    },
    setAgentLoadResults(result) {
      self.agentLoadResults = result
    },
    setReconStatements(reconStatements) {
      self.reconStatements = reconStatements
    },
    setStatistics(statistics) {
      self.statistics = statistics
    },
    setAgentLoadLastPayments(lastPayments) {
      self.agentLoadLastPayments = lastPayments
    },
    setAgentLoading(isLoading: boolean) {
      self.agentLoading = isLoading
    },
    setAgentProfileTab(value) {
      self.agentProfileTab = value
    },
    setAgentLoadPayments(payments) {
      self.agentLoadPayments = payments
    },
    setAgentLoadRequisite(requisite: string, idx: number) {
      self.agentLoadPayments[idx].requisite = requisite
    },
    setAgentLoadAmount(amount: string, idx: number) {
      self.agentLoadPayments[idx].amount = amount
    },
    setServicesCommission(servicesCommission) {
      self.servicesCommission = servicesCommission
    },
    setOption(key, value) {
      if (!self.payData.options) {
        self.payData = {}
      }
      self.payData.options[key] = value
    },
    setDate(period: moment.unitOfTime.DurationConstructor = 'day') {
      if (period === 'day') {
        self.date_from = dateFormatDB(moment(self.date_to).subtract(1, 'days'))
      } else {
        self.date_from = dateFormatDB(moment(self.date_to).subtract(1, period))
      }
      self.period = period
    },
    setOperationType(operationType) {
      self.operationType = operationType
    },
    setAgentProfile(profile) {
      self.agentProfile = profile
    },
    setHistory(history) {
      self.history = history
    },
    setFavorite(prop, val) {
      self.favorite[prop] = val
    },
    setPage(pageNumber: number) {
      self.page = pageNumber
    },
    setPageSize(pageSize: number) {
      self.page_size = pageSize
    },
    setFavoriteOptions(prop, val) {
      self.favorite.options.set(prop, val)
    },
    setFavoriteId(id) {
      self.favoriteId = id
    },
    setTotal(total) {
      self.total = total
    },
    setDateFrom(date) {
      self.date_from = dateFormatDB(date)
    },
    setDateTo(date) {
      self.date_to = dateFormatDB(date)
    },
    setSearchText(text: string) {
      self.searchText = text
    },
    setSubAgents(subAgents) {
      self.subAgents = subAgents
    },
    setAgentListType(type) {
      self.agentListType = type
    },
    setQuery(val: string) {
      self.query = val
    },
    setTemplates(templates) {
      self.templates = templates
    },
    setReportStatus(name, val) {
      self.reportStatus[name] = val
    },
    setReportFileName(name) {
      self.reportFilename = name
    },
    setSubAgent(subagent?) {
      if (subagent) {
        const subAgent = { ...subagent }
        const sexObj = self.genders.find((s) => s.id === subagent.sex)
        const template = self.templates?.find(
          (t) => t.id === subagent.template_id,
        )
        self.subAgent = subAgent
        if (sexObj) self.subAgent.setSexObj(sexObj)
        if (template) self.subAgent.setTemplate(template)
        self.subAgent.setValue('password', null)
      } else {
        self.resetSubAgent()
      }
      if (!self.subAgent.data) {
        self.subAgent.setValue('data', {})
      }

      if (!self.subAgent.data.address_info) {
        self.subAgent.data.setValue('address_info', {})
      }

      if (!self.subAgent.data.agent_info) {
        self.subAgent.data.setValue('agent_info', {})
      }

      if (!self.subAgent.data.address_info.shop_type) {
        self.subAgent.data.address_info.setValue('shop_type', undefined)
      }

      if (!self.subAgent.data.address_info.working_hours) {
        self.subAgent.data.address_info.setValue('working_hours', undefined)
      }

      if (!self.subAgent.data.address_info.shop_phone) {
        self.subAgent.data.address_info.setValue('shop_phone', null)
      }
    },
    setSubAgentsSum(val, idx) {
      self.subAgents[idx].sum = val
    },
    setSubAgentsComment(val, idx) {
      self.subAgents[idx].comment = val
    },
    setSubAgentProperty(name, value) {
      self.subAgent[name] = value
    },
    setCurrentPage(page) {
      self.currentPage = page
    },
    setOrderBy(order: string) {
      self.orderBy = order
    },
    setColumns(columns) {
      self.columns = columns
    },
    setPaketAgentLoadExcelFile(file) {
      self.paketAgentLoadExcelFile = file
    },
    setPackageService(service) {
      self.packageService = service ? getSnapshot(service) : service
    },
    incDate() {
      self.date_from = dateFormatDB(moment(self.date_from).add(1, 'day'))
      self.date_to = dateFormatDB(moment(self.date_to).add(1, 'day'))
    },
    decDate() {
      self.date_from = dateFormatDB(moment(self.date_from).subtract(1, 'day'))
      self.date_to = dateFormatDB(moment(self.date_to).subtract(1, 'day'))
    },
    resetHistory() {
      self.setHistory([])
      self.setValue('service', null)
    },
    resetPayData() {
      self.paymentState = FORM.INITIAL
      self.step = 1
      self.payData = { options: {} }
      pay24Api.cancelPaymentPoll()
    },
    resetFavorite() {
      self.setFavorite('name', '')
      self.setFavorite('requisite', '')
      self.setFavorite('service_id', 0)
      self.setFavorite('options', {})
      self.service = null
    },

    resetTemplates() {
      applySnapshot(self.templates, [])
    },
    resetAgentAkt() {
      self.reconStatements = []
      self.setDateFrom(moment())
      self.setDateTo(moment())
    },
    resetAgentStatistics() {
      self.setValue('agent', null)
      self.setValue('status', null)
      self.setValue('horizontal', false)
      self.setStatistics([])
      self.setColumns([])
      self.setDateFrom(moment())
      self.setDateTo(moment())
    },
    resetSubAgentHistory() {
      self.setValue('agent', null)
      self.setValue('operationType', null)
      self.setValue('subAgentHistoryPage', 1)
      self.setValue('subAgentHistory', [])
      self.setValue('total_good', 0)
      self.setValue('count_good', 0)
      self.setValue('total_bad', 0)
      self.setValue('count_bad', 0)
      self.setDateFrom(moment())
      self.setDateTo(moment())
    },
    resetSubAgent() {
      self.subAgent.resetSubAgent()
    },
    resetSubAdd() {
      self.subAgent.setValue('phonenumber', '')
      self.subAgent.setValue('sub_code', '')
      self.subAgent.setValue('password', null)
      self.subAgent.setValue('template_id', null)
      self.subAgent.setValue('limit', 0)
      self.subAgent.setValue('ip_address', '')
      self.subAgent?.data?.address_info.setValue('shop_name', '')
      self.subAgent?.data?.address_info.setValue('shop_type', null)
      self.subAgent?.data?.address_info.setValue('shop_city', null)
      self.subAgent?.data?.address_info.setValue('shop_country', null)
      self.subAgent?.data?.address_info.setValue('shop_region', null)
      self.subAgent?.data?.address_info.setValue('shop_district', null)
      self.subAgent?.data?.address_info.setValue('shop_address', null)
      self.subAgent?.data?.address_info.setValue('shop_home', null)
      self.subAgent?.data?.address_info.setValue('shop_longitude', '')
      self.subAgent?.data?.address_info.setValue('shop_latitude', '')
      self.subAgent?.data?.address_info.setValue('working_hours', null)
      self.subAgent?.data?.address_info.setValue('shop_phone', null)
      self.subAgent.setValue('last_name', '')
      self.subAgent.setValue('first_name', '')
      self.subAgent.setValue('patronymic', '')
      self.subAgent.setSexObj(null)
      self.subAgent.setValue('birthday', null)
      self.subAgent?.data?.agent_info.setValue('agent_city', null)
      self.subAgent?.data?.agent_info.setValue('agent_country', null)
      self.subAgent?.data?.agent_info.setValue('agent_region', null)
      self.subAgent?.data?.agent_info.setValue('agent_district', null)
      self.subAgent?.data?.agent_info.setValue('street_name', null)
      self.subAgent?.data?.agent_info.setValue('house_number', null)
      self.subAgent?.data?.agent_info.setValue('flat_number', null)
      self.subAgent?.data.setValue('inn', '')
      self.subAgent.setValue('passport', '')
      self.subAgent.setValue('passport_issuer', '')
      self.subAgent.setValue('passport_issue', '')
      self.subAgent.setValue('passport_expire', '')
    },
    validateSubAgent() {
      let valid = true
      let messages: any[] = []

      if (!self.subAgent.first_name) {
        valid = false
        messages.push(i18n.t('input_first_name'))
      }

      if (!self.subAgent.phonenumber) {
        valid = false
        messages.push(i18n.t('input_phone_number'))
      }

      if (!self.subAgent.password && !self.subAgent.id) {
        valid = false
        messages.push(i18n.t('enter_password'))
      }

      if (!valid) {
        rootStore.setMessage({
          type: MESSAGE_TYPE.WARNING,
          text: messages.join(', '),
        })
      }
      return valid
    },
    isTimeValid(time) {
      return /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(time)
    },
    validateTemplate() {
      let valid = true
      let messages: any[] = []

      if (!self.template.template) {
        valid = false
        messages.push(i18n.t('template_name'))
      }

      if (
        self.template.data.start_time &&
        !self.isTimeValid(self.template.data.start_time)
      ) {
        valid = false
        messages.push(i18n.t('time_start'))
      }

      if (
        self.template.data.end_time &&
        !self.isTimeValid(self.template.data.end_time)
      ) {
        valid = false
        messages.push(i18n.t('time_end'))
      }

      if (!valid) {
        rootStore.setMessage({
          type: MESSAGE_TYPE.WARNING,
          text: messages.join(', '),
        })
      }
      return valid
    },
    resetPayments() {
      self.setAgentLoadPayments([
        { requisite: '', amount: '' },
        { requisite: '', amount: '' },
        { requisite: '', amount: '' },
        { requisite: '', amount: '' },
      ])
    },
    deleteFavoriteOptions(prop) {
      self.favorite.options.delete(prop)
    },
    deleteResult(idx) {
      if (idx === undefined || idx === null) {
        self.setAgentLoadResults([])
      } else {
        self.agentLoadResults.splice(idx, 1)
      }
    },
    agentGetServicesInfo() {
      agentApi.getServicesInfo().then((data) => {
        self.setServicesCommission(data.services)
      })
    },
    calc_total(amount, payment_commission, acquiring_commission) {
      let calc_commission = (t, c) => {
        let commission = 0
        if (c) {
          if (isNaN(c) && c.indexOf('%') !== -1) {
            commission = (t * parseFloat(c.trim('%'))) / 100
          } else {
            commission = parseFloat(c)
          }
        }
        return commission
      }
      let total = parseFloat(amount || 0)
      total += calc_commission(total, payment_commission)
      total += calc_commission(total, acquiring_commission)
      return round(total, 2)
    },
    async uploadPaketAgentLoadExcelFile() {
      if (
        !self.paketAgentLoadExcelFile &&
        self.packagePay !== 'coca_cola' &&
        self.packagePay !== 'bi_key'
      )
        return
      const api =
        self.packagePay === 'coca_cola'
          ? agentApi.agentColaFile
          : agentApi.agentFromFile
      const base64String = await fileToBase64(self.paketAgentLoadExcelFile)
      let formData = new FormData()
      formData.append('file', base64String as string)
      if (self.packagePay === 'coca_cola') {
        formData.append('service_code', self.packageService?.type || '')
      }
      api(formData)
      self.setAgentLoadResults([{ message: 'ok', type: 'success' }])
      self.setPaketAgentLoadExcelFile(null)
      self.setPackageService(null)
      self.getLastPayments({
        service_id: [100003, 100004, 100005, 1494, 1522],
        limit: 10,
        client: 1,
      })
      rootStore.setMessage({
        type: MESSAGE_TYPE.SUCCESS,
        text: i18n.t('successfully'),
      })
    },
    startDownload: () => {
      setTimeout(() => {
        const response = {
          file: `${platform.agent.url}/agent/download?name=${self.reportFilename}&auth_token=${rootStore.token}`,
        }
        self.setReportFileName(null)
        if (isMobile) {
          downloadExcel(response.file, self.reportFilename)
        } else {
          window.open(response.file, '_blank')
        }
      }, 100)
    },
    setBlockButton(value) {
      self.blockButton = value
    },
  }
}

const views = (_self) => {
  const self = _self as IAgentStore
  return {
    get subAgentStatus() {
      if (self.subAgent.is_blocked) {
        return i18n.t('blocked')
      } else if (self.subAgent.is_closed) {
        return i18n.t('closed')
      }
      return i18n.t('active')
    },
    get canAddAgent() {
      return (
        self.subAgent.first_name &&
        self.subAgent.password &&
        self.subAgent.phonenumber &&
        self.subAgent.sub_code &&
        self.subAgent.ip_address
      )
    },
    get canSaveProfile() {
      const { last_name, first_name, inn, passport, passport_issuer } =
        self.agentProfile

      const is_valid_last_name = !!last_name?.trim()
      const is_valid_first_name = !!first_name?.trim()
      const is_valid_inn = !!inn?.trim()
      const is_valid_passport = !!passport?.trim()
      const is_valid_passport_issuer = !!passport_issuer?.trim()

      const canSave =
        is_valid_last_name &&
        is_valid_first_name &&
        is_valid_inn &&
        is_valid_passport &&
        is_valid_passport_issuer

      return {
        canSave,
        is_valid_last_name,
        is_valid_first_name,
        is_valid_inn,
        is_valid_passport,
        is_valid_passport_issuer,
      }
    },
    get subAgentStatusBlockText() {
      return self.subAgent.is_blocked
        ? i18n.t('unblock_user')
        : i18n.t('block_user')
    },
    get hasTemplateDaySettings() {
      return (
        self.template.data.Mon ||
        self.template.data.Tue ||
        self.template.data.Wed ||
        self.template.data.Thu ||
        self.template.data.Fri ||
        self.template.data.Sat ||
        self.template.data.Sun
      )
    },
    get isReportReady() {
      return (
        self.reportStatus.job_id === null && self.reportStatus.status === null
      )
    },
    getFavoriteOptions(prop) {
      return self.favorite.options.get(prop)
    },
    get saveFavoriteDisabled() {
      return !self.favorite.requisite || !self.favorite.name
    },
    get getSearchText() {
      return self.searchText !== ''
    },
    get getSubAgentsData() {
      return self.subAgents
    },
    get getTemplateName() {
      return self.templateName
    },
    get getOrderBy() {
      return self.orderBy === 'phonenumber'
    },
    get getSubCode() {
      return self.subAgent.sub_code
    },
    get getSubPhone() {
      return self.subAgent.phonenumber
    },
    get getSubLimit() {
      return self.subAgent.limit
    },
    get getSubPassword() {
      return self.subAgent.password
    },
    get getParams() {
      const param: any = {
        date_from: dateFormat(self.date_from),
        date_to: dateFormat(self.date_to),
        operation_type: self.operationType?.id,
      }
      if (rootStore.user?.data?.agent?.id) {
        param.user_id = rootStore.user?.data?.agent?.id
      }
      return param
    },
    getSubAgentHistoryParams() {
      const param: any = {
        date_from: dateFormat(self.date_from),
        date_to: dateFormat(self.date_to),
        operation_type: self.operationType?.id,
      }

      if (self.agent) {
        param.user_id = self.agent
      }
      return param
    },
    getAgentStatisticsParams() {
      const param: any = {
        date_from: dateFormat(self.date_from),
        date_to: dateFormat(self.date_to),
      }

      if (self.agent) {
        param.user_id = self.agent
      }

      if (self.agentStatisticsStatus) {
        param.type = self.agentStatisticsStatus
      }

      if (self.horizontal || isMobile) {
        param.horizontal = self.horizontal
      }

      return param
    },
    get historySearchResult() {
      return self.subAgentHistory.filter(
        (a) =>
          (a.sub_code &&
            a.sub_code
              .toLocaleLowerCase()
              .includes(self.searchText.toLocaleLowerCase())) ||
          (a.service_name &&
            a.service_name
              .toLocaleLowerCase()
              .includes(self.searchText.toLocaleLowerCase())) ||
          (a.requisite && a.requisite.includes(self.searchText)) ||
          (a.transaction && a.transaction.includes(self.searchText)) ||
          (a.note &&
            a.note
              .toLocaleLowerCase()
              .includes(self.searchText.toLocaleLowerCase())) ||
          (a.amount && a.amount.toString().includes(self.searchText)) ||
          (a.commission && a.commission.toString().includes(self.searchText)),
      )
    },
    get categoryName() {
      return self.category?.name
    },
  }
}

const volatile = () => {
  return {
    paketAgentLoadExcelFile: null,
    agentLoading: true,
  }
}

export const AgentStoreModel = types
  .compose(types.model(properties), ServicesStore, editableModel())
  .volatile(volatile)
  .actions(actions)
  .views(views)
  .named('AgentStore')

interface IAgentStore extends Instance<typeof AgentStoreModel> {}
export interface IServiceModel extends Instance<typeof AgentAllServicesModel> {}
export interface ICategoryModel extends Instance<typeof AgentCategoryModel> {}

const AgentStore = AgentStoreModel.create()
export default AgentStore
